import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/el_GR/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/aftersun/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/aftersun/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/aftersun-s-gr.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/aftersun-s-gr-mobile.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> AFTER SUN
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>
                        Αναζωογόνηση του δέρματος μετά την έκθεση στον ήλιο
                      </h3>
                      <p>
                        Μετά από μια μέρα κάτω από τον ήλιο, η επιδερμίδα σας
                        χρειάζεται αναζωογόνηση και ενυδάτωση. Φροντίστε την με
                        προϊόντα after sun, για την αποκατάσταση της ισορροπίας
                        της φυσικής υγρασίας της επιδερμίδας. Εμπλουτισμένο με
                        ισχυρό σύμπλεγμα ενυδάτωσης, το PIZ BUIN<sup>®</sup>{" "}
                        AFTER SUN συμβάλλει στην προστασία του δέρματος από την
                        αφυδάτωση και προσφέρει πιο όμορφο μαύρισμα που διαρκεί
                        περισσότερο. Όλα τα προϊόντα PIZ BUIN<sup>®</sup> AFTER
                        SUN περιέχουν βιταμίνη E, η οποία βοηθά στην προστασία
                        κατά της πρόωρης γήρανσης της επιδερμίδας που συνδέεται
                        με την έκθεση στον ήλιο.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="instant-relief-mist-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-after-sun-instant-mist-spray-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>INSTANT RELIEF MIST SPRAY</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Το σπρέι PIZ BUIN® After Sun Instant Relief Mist Spray
                          για μετά τον ήλιο, καταπραΰνει άμεσα κι αναζωογονεί
                          την επιδερμίδα σας. Χάρη στη μοναδική του σύνθεση με
                          υαλουρονικό οξύ και γλυκερίνη, προσφέρει 48ωρη
                          ενυδάτωση που αποκαθιστά την ισορροπία της φυσικής
                          υγρασίας του δέρματος και προλαμβάνει το ξεφλούδισμα,
                          για μαύρισμα που διαρκεί περισσότερο. Mε σύστημα
                          ψεκασμού πολλαπλών θέσεων εφαρμόζεται εύκολα ενώ χάρη
                          στην ελαφριά μη λιπαρή του σύνθεση, απορροφάται
                          γρήγορα από την επιδερμίδα σας και δεν κολλάει.{" "}
                        </p>
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ</h4>
                        <p>
                          Οδηγίες χρήσης: Για 48ωρη ενυδάτωση, επαναλάβετε την
                          εφαρμογή μετά το μπάνιο σε στεγνό δέρμα.{" "}
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ALOE VERA
                                <div className="textBlock bottom">
                                  <p>
                                    Why is Aloe Vera so good?
                                    <br />
                                    Aloe Vera is a rich natural moisturiser with
                                    powerful soothing properties. It contains
                                    some 75 active substances, including 11
                                    minerals, 16 amino acids, and 8 different
                                    vitamins.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/006e44dceb4947a77acdd3948503af8d_f37.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Γλυκερίνη
                                <div className="textBlock bottom">
                                  <p>
                                    Η γλυκερίνη προσελκύει το νερό και το
                                    συγκρατεί στο δέρμα, το οποίο βοηθά στη
                                    διατήρηση ενός καλού επιπέδου ενυδάτωσης.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                ΒΙΤΑΜIΝΗ Ε
                                <div className="textBlock bottom">
                                  <p>
                                    Η βιταμίνη Ε είναι ένα ισχυρό φυσικό
                                    αντιοξειδωτικό που βοηθά στην προστασία από
                                    τις ελεύθερες ρίζες, οι οποίες καταστρέφουν
                                    τα κύτταρα και προκαλούν πρόωρη γήρανση του
                                    δέρματος.
                                  </p>
                                  <p>
                                    Η βιταμίνη E περιέχεται στο PIZ BUIN
                                    <sup>®</sup>
                                  </p>
                                  <p>
                                    After Sun Tan Intensifying Moisturising
                                    Lotion.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                MINT EXTRACTMINT EXTRACT
                                <div className="textBlock bottom">
                                  <p>
                                    Mint is widely used for various medicinal
                                    and cosmetic purposes. Amongst other it’s
                                    known for it’s strong natural cooling
                                    properties.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fdf3033b2f5f4b9f6c2f8a54acf02d47_f45.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="tan-intensifying-moisturisation-lotion" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-after-sun-tan-intensifying-moisturising-lotion-200ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>TAN INTENSIFYING MOISTURISATION LOTION</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Η νέα PIZ BUIN<sup>®</sup> AFTER SUN Tan Intensifying
                          Lotion περιέχει το συστατικό Tanimel, ένα φυτικό
                          εκχύλισμα που ενισχύει το μαύρισμα και διεγείρει τη
                          διαδικασία παραγωγής των φυσικών χρωστικών ουσιών του
                          δέρματος που απαιτούνται για το μαύρισμα*. Η φόρμουλα
                          γρήγορης απορρόφησης παρέχει 24ωρη ενυδάτωση που
                          αποκαθιστά την ισορροπία της φυσικής υγρασίας στο
                          ταλαιπωρημένο από τον ήλιο δέρμα και αποτρέπει το
                          ξεφλούδισμα για μαύρισμα που διαρκεί περισσότερο.
                          <br />
                          <span className="footNote">*In vitro δοκιμές</span>
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            <span className="footNote">
                              <br />
                            </span>
                          </p>
                          <h2>
                            Διαθέσιμο σε
                            <span
                              style={{
                                "font-size": "10px"
                              }}
                            >
                              {" "}
                            </span>
                          </h2>
                          <p> 200 ml</p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Διαβάστε περισσότερα
                        </button>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Γλυκερίνη
                                <div className="textBlock bottom">
                                  <p>
                                    Η γλυκερίνη προσελκύει το νερό και το
                                    συγκρατεί στο δέρμα, το οποίο βοηθά στη
                                    διατήρηση ενός καλού επιπέδου ενυδάτωσης.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                ΒΙΤΑΜIΝΗ Ε
                                <div className="textBlock bottom">
                                  <p>
                                    Η βιταμίνη Ε είναι ένα ισχυρό φυσικό
                                    αντιοξειδωτικό που βοηθά στην προστασία από
                                    τις ελεύθερες ρίζες, οι οποίες καταστρέφουν
                                    τα κύτταρα και προκαλούν πρόωρη γήρανση του
                                    δέρματος.
                                  </p>
                                  <p>
                                    Η βιταμίνη E περιέχεται στο PIZ BUIN
                                    <sup>®</sup>
                                  </p>
                                  <p>
                                    After Sun Tan Intensifying Moisturising
                                    Lotion.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Βούτυρο καριτέ
                                <div className="textBlock bottom">
                                  <p>
                                    Το βούτυρο καριτέ προέρχεται από τους
                                    καρπούς του δέντρου shea, το οποίο φυτρώνει
                                    στη δυτική Αφρική. Χρησιμοποιείται εδώ και
                                    αιώνες για την ενυδάτωση και την προστασία
                                    του δέρματος από τις επιδράσεις του ήλιου,
                                    του αέρα, της ζέστης και του αλμυρού νερού.
                                    Επιπλέον, χρησιμοποιείται ευρέως στην
                                    κοσμετολογία λόγω των ενυδατικών και
                                    προστατευτικών ιδιοτήτων του.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4e8fd1f288f22f5c0578667ed26ebe09_f46.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Tanimel
                                <div className="textBlock bottom">
                                  <p>
                                    Το Tanimel είναι ένα ενεργό συστατικό που
                                    προέρχεται από το φυτό ονωνίδα (rest
                                    harrow), το οποίο συναντάται συχνά στην
                                    Ευρώπη.
                                  </p>
                                  <p>
                                    Συμβάλλει στη διατήρηση της φυσικής
                                    παραγωγής μελανίνης του οργανισμού με ή
                                    χωρίς ήλιο, ώστε να διαρκεί περισσότερο το
                                    φυσικό σας μαύρισμα*. Αυξάνει επίσης την
                                    παραγωγή μελανίνης κατά 133%*, οδηγώντας σε
                                    βαθύτερο μαύρισμα.
                                  </p>
                                  <p>*In vitro δοκιμές</p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/6121365f8827c636e3a96d865c44ae54_f47.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/gr/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/hydro-infusion/" target="_self">
                      Hydro Infusion
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/sensitive/" target="_self">
                      Sensitive
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/gr/our-products/aftersun/"
                      target="_self"
                    >
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Διαβάστε περισσότερα');\n} else {\nbox.slideDown();\n$(this).text('Διαβάστε λιγότερα');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
